<template>
  <div class="course">
    <div class="coursePC">
      <div
        class="courseContainer"
        v-for="(item, index) of viewState.courses"
        :key="index"
        :style="{
          'background-image':
            viewState.courseHovered && index == viewState.hoverIndex
              ? `url(${require('../../assets/img/course-hover-bg.png')})`
              : 'none',
        }"
        @mouseover="courseContainerMouseover(item, index)"
        @mouseleave="viewState.courseHovered = false"
      >
        <div
          class="courseImg"
          :style="{
            'background-image': `url(${item.image_url})`,
            border:
              viewState.courseHovered && index == viewState.hoverIndex
                ? '0.5px solid rgba(0, 0, 0, 0.5)'
                : '0.5px solid rgba(255,255,255,0)',
          }"
        />
        <div class="resume">{{ item.name }}</div>
        <div class="detail">{{ item.overview }}</div>
        <div class="spliteLine" />
        <div class="propSummary">
          <template v-if="isEnterprise">
            <div class="requiredLessonCount">
              <span class="topText">講座数</span>
              <span class="bottomText">{{ item.lectures_count }}</span>
            </div>
            <div class="verticalLine" />
            <div class="remainingDays">
              <span class="topText">公開日数</span>
              <span class="bottomText" :class="item.remaining_days !== null && item.remaining_days < 10 ? 'expire-soon' : ''">
                <template v-if="item.remaining_days">
                  残り{{ item.remaining_days }}日
                </template>

                <template v-else>無制限</template>
              </span>
            </div>
          </template>

          <template v-else>
            <div class="level">
              <span class="topText">レベル</span>
              <span class="bottomText">
                <template v-if="item.lecture_level_ids.length > 1">
                  {{ viewState.levels[item.lecture_level_ids.slice(0, 1)].name }}〜
                </template>
                <template v-else-if="item.lecture_level_ids.length == 1">
                  {{ viewState.levels[item.lecture_level_ids[0]].name }}
                </template>
              </span>
            </div>
            <div class="verticalLine" />
            <div class="lessonCount">
              <span class="topText">講座</span>
              <span class="bottomText">{{ item.lecture_count }}</span>
            </div>
            <div class="verticalLine" />
            <div class="lengthOfTime">
              <span class="topText">視聴時間</span>
              <span class="bottomText">{{
                display.toTime(item.total_attendance_time)
              }}</span>
            </div>
          </template>
        </div>
        <div class="courseFooterBtn" @click="toLearnCourse(item.code)">
          <div class="hoveredBgColor" />
          <span class="footerBtnText">コース詳細を見る</span>
          <icon name="chapter-detail-btn" />
        </div>
        <!-- todo -->
      </div>
    </div>

    <div class="courseSP">
      <div
        class="courseContainer"
        v-for="(item, index) of viewState.courses"
        :key="index"
        :style="{
          'background-image': `url(${require('../../assets/img/course-sp-bg.png')})`,
        }"
      >
        <div
          class="courseImg"
          :style="{
            'background-image': `url(${item.image_url})`,
          }"
        />
        <div class="resume">{{ item.name }}</div>
        <div class="detail">{{ item.overview }}</div>
        <div class="spliteLine" />
        <div class="propSummary">
          <template v-if="isEnterprise">
            <div class="requiredLessonCount">
              <span class="topText">講座数</span>
              <span class="bottomText">
                {{ item.lectures_count }}
              </span>
            </div>
            <div class="verticalLine" />
            <div class="remainingDays">
              <span class="topText">公開日数</span>
              <span class="bottomText" :class="item.remaining_days !== null && item.remaining_days < 10 ? 'expire-soon' : ''">
                <template v-if="item.remaining_days">
                  残り{{ item.remaining_days }}日
                </template>

                <template v-else>無制限</template>
              </span>
            </div>
          </template>
          <template v-else>
            <div class="level">
              <span class="topText">レベル</span>
              <span class="bottomText">
                <template v-if="item.lecture_level_ids.length > 1">
                  {{ viewState.levels[item.lecture_level_ids.slice(0, 1)].name }}〜
                </template>
                <template v-else-if="item.lecture_level_ids.length == 1">
                  {{ viewState.levels[item.lecture_level_ids[0]].name }}
                </template>
              </span>
            </div>
            <div class="verticalLine" />
            <div class="lessonCount">
              <span class="topText">レッスン</span>
              <span class="bottomText">{{ item.lecture_count }}</span>
            </div>
            <div class="verticalLine" />
            <div class="lengthOfTime">
              <span class="topText">視聴時間</span>
              <span class="bottomText">{{
                display.toTime(item.total_attendance_time)
              }}</span>
            </div>
          </template>
        </div>
        <div class="courseFooterBtn" @click="toLearnCourse(item.code)">
          <span class="footerBtnText">コース詳細を見る</span>
          <icon name="chapter-detail-btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import display from "@/utils/display";
import util from "@/utils/util";
import http from "@/utils/http";
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElLoading } from "element-plus";

export default defineComponent({
  name: "courseList",
  setup() {
    const store = useStore();
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise)
    const router = useRouter();

    const viewState = reactive({
      isDisplayed: false,
      courseHoveredBgImgUrl: "",
      courseHovered: false,
      hoverIndex: -1,
      courses: {},
      levels:  [],
    });

    // method
    const courseContainerMouseover = (item, index) => {
      viewState.courseHoveredBgImgUrl = item.hoveredBgImgUrl;
      viewState.courseHovered = true;
      viewState.hoverIndex = index;
    };
    const toLearnCourse = (courseCode) => {
      router.push({
        name: "courseDetail",
        params: { courseCode: courseCode },
      });
    };
    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchCourses = async () => {
      // 並び順順
      return await http
        .get("/api/courses?order=3")
        .then((response) => {
          response.data.forEach((course) => {
            util.sortBy(course.lecture_level_ids); // 昇順
          });
          viewState.courses = util.keyBy(response.data, "code");
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };
    const fetchCustomCourses = async () => {
      return await http
        .get("/api/custom-courses")
        .then((response) => {
          console.dir(response.data)
          viewState.courses = response.data;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };

    const reInit = async () => {
      console.log("re-init");
      init();
    };
    const init = async () => {
      if (isEnterprise.value) {
        await fetchCustomCourses();
      } else {
        fetchLevels();
        await fetchCourses();
      }

      viewState.isDisplayed = true; // 表示OK
    };

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングでコース情報を取得する
        console.log("skip on-mount process");
        return;
      }

      init();
    });

    const loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });
    watch(
      () => viewState.isDisplayed,
      () => {
        loading.close();
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );

    return {
      display,
      viewState,
      isEnterprise,
      courseContainerMouseover,
      toLearnCourse,
    };
  },
});
</script>

<style lang="scss" scoped>
.course {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  .coursePC {
    display: flex;
    padding: 65px 87px 37px 65px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .courseContainer {
      width: 360px;
      height: 640px;
      margin-right: 28px;
      @media screen and (max-width: 1383px) {
        margin-right: 27.5px;
      }
      margin-bottom: 28px;
      display: flex;
      flex-direction: column;
      background-repeat: no-repeat;
      background-size: cover;
      &:hover {
        filter: drop-shadow(0px 0px 45px rgba(0, 0, 0, 0.1));
        box-shadow: "0px 4px 34px rgba(0, 0, 0, 0.25)";
      }
      .courseImg {
        width: 360px;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .resume {
        min-height: 60px;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: left;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
      }
      .detail {
        min-height: 60px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 26px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
        opacity: 0.8;
      }
      .spliteLine {
        margin-top: 30px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        height: 0px;
        width: 100%;
      }
      .propSummary {
        height: 80px;
        display: flex;
        flex-direction: row;
        .verticalLine {
          width: 0px;
          height: 20px;
          margin-top: 30.07px;
          margin-bottom: 29.93px;
          border-right: 0.5px solid $black-50;
        }
        .level {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 105.95px;
          .topText {
            height: 17px;
            margin-top: 16.07px;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .bottomText {
            height: 28px;
            margin-bottom: 15.93px;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .lessonCount {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 132.78px;
          .topText {
            height: 17px;
            margin-top: 16.07px;
            font-weight: 500;
            font-size: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .bottomText {
            height: 28px;
            margin-bottom: 15.93px;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .lengthOfTime {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 121.27px;
          .topText {
            height: 17px;
            margin-top: 16.07px;
            font-weight: 500;
            font-size: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .bottomText {
            height: 28px;
            margin-bottom: 15.93px;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .requiredLessonCount {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 180px;
          .topText {
            height: 17px;
            margin-top: 16.07px;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .bottomText {
            height: 28px;
            margin-bottom: 15.93px;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .remainingDays {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 180px;
          .topText {
            height: 17px;
            margin-top: 16.07px;
            font-weight: 500;
            font-size: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .bottomText {
            height: 28px;
            margin-bottom: 15.93px;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
            
            &.expire-soon {
              color: $red;
            }
          }
        }
      }
      .courseFooterBtn {
        cursor: pointer;
        height: 60px;
        background: $black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .footerBtnText {
          margin-left: 30px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $white;
        }
        svg {
          height: 10px;
          width: 40px;
          margin-right: 15px;
        }
        .hoveredBgColor {
          position: absolute;
          height: 100%;
          background: $primary;
        }
        &:hover {
          .footerBtnText {
            z-index: 1;
          }
          svg {
            z-index: 1;
          }
          .hoveredBgColor {
            animation: footBtnHoveredAnim 0.8s linear 0s 1 forwards;
          }
          @keyframes footBtnHoveredAnim {
            0% {
              left: 0;
              width: 0;
            }
            37.5% {
              left: 0;
              width: 100%;
            }
            75% {
              left: 0;
              width: 100%;
            }
            100% {
              left: 100%;
              width: 0;
            }
          }
        }
      }
    }
  }
  .courseSP {
    display: none;
    background: white;
    padding: 0px 50px 30px 30px;
    margin: 0 auto;
    flex-direction: column;
    .courseContainer {
      display: flex;
      width: 275px;
      height: 573px;
      margin-top: 30px;
      flex-direction: column;
      background-repeat: no-repeat;
      background-size: cover;
      filter: drop-shadow(0px 0px 45px rgba(0, 0, 0, 0.1));
      box-shadow: "0px 4px 34px rgba(0, 0, 0, 0.25)";
      .courseImg {
        width: 275px;
        height: 235px;
        background-repeat: no-repeat;
        background-size: cover;
        border: 0.5px solid rgba(0, 0, 0, 0.5);
      }
      .resume {
        width: 235px;
        height: 60px;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
      }
      .detail {
        width: 235px;
        height: 60px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
        opacity: 0.8;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
      }
      .spliteLine {
        margin-top: 30px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        height: 0px;
        width: 100%;
      }
      .propSummary {
        height: 78px;
        display: flex;
        flex-direction: row;
        .verticalLine {
          width: 0px;
          height: 15px;
          margin-top: 31.5px;
          margin-bottom: 31.5px;
          border-right: 0.5px solid $black-50;
        }
        .level {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 96px;
          .topText {
            height: 10px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
          .bottomText {
            height: 18px;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .lessonCount {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 90px;
          .topText {
            height: 10px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
          .bottomText {
            height: 18px;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .lengthOfTime {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 89px;
          .topText {
            height: 10px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
          .bottomText {
            height: 18px;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .requiredLessonCount {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 137.5px;
          .topText {
            height: 10px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
          .bottomText {
            height: 18px;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
        }
        .remainingDays {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 137.5px;
          .topText {
            height: 10px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
          .bottomText {
            height: 18px;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;

            &.expire-soon {
              color: $red;
            }
          }
        }
      }
      .courseFooterBtn {
        height: 60px;
        background: $black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        svg {
          height: 10px;
          width: 40px;
          margin-right: 20px;
        }
        .footerBtnText {
          margin-left: 20px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $white;
        }
      }
    }
  }
}
@include mq(sm) {
  .course {
    .coursePC {
      display: none;
    }
    .courseSP {
      display: flex;
    }
  }
}
</style>
