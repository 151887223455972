<template>
  <div class="course">
    <div class="headerContainer">
      <icon name="course-bg2" class="bgImgWhiteLine" />
      <icon name="course-sp-bg2" class="spBgImgWhiteLine" />

      <icon name="bg-dot-circle" class="bgImgLeftBottomBg" />
      <div class="shareContainer">
        <shareBtn/>
      </div>

      <span class="textBig">{{ viewState.textBig }}</span>
      <span class="textSmall">{{ viewState.textSmall }}</span>
    </div>
    <div class="courseCard">
      <courseList />
      <div class="courseCardleftBottomBg" />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import courseList from "@/views/course/courseList.vue";
import shareBtn from '@/components/CommonShare/CommonShare.vue'
import { ElLoading } from "element-plus";

export default defineComponent({
  components: {
    courseList,
    shareBtn
  },
  setup() {
    const store = useStore();
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise)
    const viewState = reactive({
      isDisplayed: false,
      textBig: "",
      textSmall: "",
    });

    window.scrollTo(0, 0);

    const reInit = () => {
      console.log("re-init");
      init();
    };
    const init = () => {
      if (isEnterprise.value) {
        viewState.textBig = "表示されているコースの中から\n受講する講座を選んで下さい";
        viewState.textSmall = "講座毎に設定されている公開期限内に必修講座の受講を完了するようお願いいたします。";
      } else {
        viewState.textBig = "選べる5コースから\n目的別に合わせてDXを始めよう！";
        viewState.textSmall = "DXの読み書き算盤となる専門４領域＋基礎で設計されたコースで次世代のIT人材になるための学習ができます。";
      }

      viewState.isDisplayed = true; // 表示OK
    };
    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで表示メッセージを設定する
        console.log("skip on-mount process");
        return;
      }

      init();
    });
    const loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });
    watch(
      () => viewState.isDisplayed,
      () => {
        loading.close();
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );
    return {
      viewState,
    };
  },
});
</script>

<style lang="scss" scoped>
.course {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1366px;
  margin: 0 auto;
  .headerContainer {
    position: relative;
    max-width: 1366px;
    min-height: 496px;
    background-image: url(../../assets/img/course-bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    .bgImgWhiteLine {
      position: absolute;
      width: 1252px;
      height: 402px;
      right: 115px;
    }
    .spBgImgWhiteLine {
      display: none;
    }
    .bgImgLeftBottomBg {
      position: absolute;
      width: 620px;
      height: 620px;
      left: -310px;
      bottom: -310px;
      z-index: -1;
    }
    .shareContainer {
      position: absolute;
      right: 116px;
      bottom: 138px;
      width: 83px;
      height: 19px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
      .common-share {
        .common-share-container {
          .common-share-icon {
            width: 18px;
            height: 18px;
          }
          .common-share-icon-active {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .textBig {
      position: relative;
      display: block;
      white-space: pre-wrap;
      text-align: left;
      max-width: 638px;
      margin-left: 115px;
      margin-top: 100px;
      margin-right: 70px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 80px;
      letter-spacing: 0.05em;
      color: $black;
    }
    .textSmall {
      display: block;
      position: relative;
      margin-left: 115px;
      margin-top: 26px;
      margin-right: 100px;
      margin-bottom: 180px;
      text-align: left;
      max-width: 688px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $dark-grey;
    }
  }
  .courseCard {
    position: relative;
    margin-top: -100px;
    margin-left: 50px;
    margin-bottom: 50px;
    background: blanchedalmond;
    .courseCardleftBottomBg {
      position: absolute;
      z-index: -1;
      width: 584px;
      height: 584px;
      background: $secondary;
      opacity: 0.4;
      border-radius: 50%;
      left: -342px;
      bottom: -349px;
    }
  }
}
@include mq(sm) {
  .course {
    .headerContainer {
      height: 685px;
      background-image: url(../../assets/img/course-sp-bg1.png);
      .bgImgWhiteLine {
        display: none;
      }
      .spBgImgWhiteLine {
        display: block;
        position: absolute;
        width: 345.46px;
        height: 480.65px;
        right: 30px;
      }
      .bgImgLeftBottomBg {
        left: -141px;
        bottom: -141px;
        width: 282px;
        height: 282px;
      }
      .shareContainer {
        width: 66px;
        right: 33px;
        bottom: 283px;
      }
      .shareIcon {
        margin-left: 8px;
      }
      .textBig {
        margin: 80px 50px 0;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
      }
      .textSmall {
        margin: 24px 50px 0;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
    }
    .courseCard {
      margin-top: -265px;
      margin-left: 20px;
      margin-bottom: 20px;
      .courseCardleftBottomBg {
        width: 255px;
        height: 255px;
        bottom: -117px;
        left: -128px;
      }
    }
  }
}
</style>